<template>
  <div class="base-cancel-button">
    <button class="cancel-button" type="button" @click="handleClick">
      <span class="cancel-button-text">
        <slot></slot>
      </span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseCancelButton',

  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.base-cancel-button {
  @include selector-width('.cancel-button', 335px);
  > .cancel-button {
    height: 60px;
    border-radius: 6px;
    border: solid 2px #{$plus_orange};
    background-color: #{$plus_white};
    padding: 0;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
      background-color: #{$plus_ee_gray};
    }
    &:active {
      margin: 2px auto 0 auto;
      height: 58px;
    }
    > .cancel-button-text {
      height: 29px;
      @include font-size(20);
      font-weight: bold;
      line-height: 1.45;
      color: #{$plus_black};
    }
  }
}
</style>
