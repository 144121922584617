<template>
  <div class="information-list-item-patient">
    <template v-if="patientList">
      <div
        class="information-list-item-patient-content"
        v-for="patient in patientList"
        :key="patient.id"
      >
        {{ patient.speciesId }}
        {{ patient.breed.length !== 0 ? `（${patient.breed}）` : '' }}:
        {{ patient.name }}
      </div>
    </template>
    <template v-if="reservationInfoList">
      <div
        class="information-list-item-patient-content"
        v-for="reservationInfo in reservationInfoList"
        :key="reservationInfo.id"
      >
        {{ displaySpecies(reservationInfo) }}
        {{
          reservationInfo.breed.length !== 0
            ? `（${reservationInfo.breed}）`
            : ''
        }}:
        {{ reservationInfo.patientName }}
      </div>
    </template>
  </div>
</template>
<script>
import { localizeValue } from '@/utils/reservation_format'
export default {
  name: 'InformationListItemPatient',

  props: {
    patientList: {
      type: Array
    },
    reservationInfoList: { type: Array }
  },
  methods: {
    displaySpecies(reservationInfo) {
      const { species, speciesEnglish } = reservationInfo
      return localizeValue(species, speciesEnglish, this.$i18n.locale)
    }
  }
}
</script>
<style lang="scss" scoped>
.information-list-item-patient {
  > .information-list-item-patient-content {
    text-align: left;
    padding-bottom: 8px;
    word-break: keep-all;
    @include font-size(18);
    &:last-child {
      padding-bottom: 0px;
    }
  }
}
@media (min-width: 641px) {
  .information-list-item-patient {
    > .information-list-item-patient-content {
      > .br-patient {
        display: none;
      }
    }
  }
}
</style>
