<template>
  <div class="registration-confirm">
    <div class="page-title">
      <base-page-title>{{
        $t('registrationConfirmChangeReservation.pageTitle')
      }}</base-page-title>
    </div>
    <div class="reservation-progress-bar">
      <progress-bar v-bind="progressBarData" />
    </div>
    <div class="reservation-confirm">
      <information-list-login-reservation
        :reservation-info-list="reservationInfoList"
      />
    </div>
    <div class="next-button">
      <base-decision-button @click="fetchReservations" :disabled="waitFlg">{{
        $t('registrationConfirmChangeReservation.buttonProceed')
      }}</base-decision-button>
    </div>
    <div class="back-button">
      <base-cancel-button @click="backPage">{{
        $t('registrationConfirmChangeReservation.buttonBack')
      }}</base-cancel-button>
    </div>
    <announce-popup
      v-if="alertFlg"
      :type="'failure'"
      :title="$t('common.error')"
      :buttons="buttons"
      @close="alertFlg = false"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import BasePageTitle from '@/components/parts/atoms/BasePageTitle.vue'
import ProgressBar from '@/components/parts/organisms/ProgressBar.vue'
import InformationListLoginReservation from '@/components/parts/organisms/InformationListLoginReservation.vue'
import BaseDecisionButton from '@/components/parts/atoms/BaseDecisionButton.vue'
import BaseCancelButton from '@/components/parts/atoms/BaseCancelButton.vue'
import { getAxiosObject } from '@/utils/library'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import ReloadConfirm from '@/components/mixins/ReloadConfirm'
import { createProgressBarDataForReservation } from '@/utils/createProgressBarData'

export default {
  name: 'RegistrationConfirm',
  components: {
    BasePageTitle,
    ProgressBar,
    InformationListLoginReservation,
    BaseDecisionButton,
    BaseCancelButton,
    AnnouncePopup
  },
  mixins: [ReloadConfirm],
  data() {
    return {
      progressBarData: {},
      reservationInfoList: [],
      scenario: this.$store.getters['auth/scenario'],
      newOwner: this.$store.getters['owner/getNewOwner'],
      newPatients: this.$store.getters['patient/getNewPatients'],
      species: this.$store.getters['species/getData'],
      newReservation: this.$store.getters['reservation/getNewReservation'],
      reservationPurposes: this.$store.getters[
        'reservationPurpose/getReservationPurposes'
      ],
      alertFlg: false,
      buttons: [this.$t('common.close')],
      popupMessage: this.$t(
        'registrationConfirmChangeReservation.errorCommunication'
      ),
      waitFlg: false
    }
  },

  created() {
    this.progressBarData = createProgressBarDataForReservation(this.scenario, 5)
    this.reservationInfoList = this.newPatients.map((patient, i) => {
      const species = this.species.find(v => v.id === patient.speciesId)
      const reservationPurpose = this.reservationPurposes.find(
        v => v.id === this.newReservation.reservationPurposeId
      )
      return {
        id: i + 1,
        purpose: reservationPurpose.name,
        purposeEnglish: reservationPurpose.nameEnglish,
        date: this.newReservation.date,
        time: this.newReservation.startTime,
        ownerLastName: this.newOwner.lastName,
        ownerFirstName: this.newOwner.firstName,
        ownerLastNameKana: this.newOwner.lastNameKana,
        ownerFirstNameKana: this.newOwner.firstNameKana,
        species: species.name,
        speciesEnglish: species.nameEnglish,
        breed: patient.breed,
        patientName: patient.name,
        tel: this.newOwner.tel,
        email: this.newOwner.email,
        postalCode: this.newOwner.postalCode,
        prefectureId: this.newOwner.prefectureId,
        address: this.newOwner.address,
        building: this.newOwner.building,
        memo: this.newReservation.memo
      }
    })
  },

  methods: {
    async fetchReservations() {
      this.waitFlg = true
      try {
        const { reEnterPassword, ...ownerData } = this.newOwner
        const axiosObject = getAxiosObject()
        await axiosObject.post('/reservations/authentication-email', {
          owner: {
            ...ownerData,
            englishFlg: this.$i18n.locale === 'en' ? 1 : 0
          },
          patients: this.newPatients,
          reservation: this.newReservation
        })
        this.$router.push({ name: 'SendMailFirstReservation' })
      } catch (ex) {
        this.alertFlg = true
        this.waitFlg = false
        if (ex.response?.status === 401) {
          this.buttons = [this.$t('common.buttonGoToLogin')]
        } else if (ex.response?.data?.extra === 'blank memo') {
          this.popupMessage = this.$t(
            'registrationConfirmChangeReservation.errorMemoRequired'
          )
          this.buttons = [this.$t('common.close')]
        } else {
          this.popupMessage = this.$t(
            'registrationConfirmChangeReservation.errorCommunication'
          )
          this.buttons = [this.$t('common.close')]
        }
      }
    },
    backPage() {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.registration-confirm {
  > .page-title {
    margin-top: 70px;
  }
  > .reservation-progress-bar {
    margin: 28px 0 61px;
  }
  > .next-button {
    margin-top: 80px;
  }
  > .back-button {
    margin: 23px 0 80px 0;
  }
}
@media (max-width: $global-media-width) {
  .registration-confirm {
    > .reservation-progress-bar {
      margin-bottom: 66px;
    }
    > .next-button {
      margin-top: 83px;
    }
  }
}
</style>
