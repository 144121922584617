<template>
  <div class="information-list-login-reservation">
    <information-list-item>
      <template #title>
        {{ $t('common.labelPurposeOfVisit') }}
      </template>
      <template #content>
        {{ reservationPurpose }}
      </template>
    </information-list-item>

    <information-list-item>
      <template #title>
        {{ $t('common.labelDateRequested') }}
      </template>
      <template #content>
        {{ formattedDate }}
      </template>
    </information-list-item>

    <information-list-item>
      <template #title>
        {{ $t('common.labelName') }}
      </template>
      <template #content>
        {{ ownerFullName }}
      </template>
    </information-list-item>

    <information-list-item>
      <template #title>
        {{ $t('common.labelKatakana') }}
      </template>
      <template #content>
        {{ ownerFullNameKana }}
      </template>
    </information-list-item>

    <information-list-item>
      <template #title>
        {{ $t('common.labelPetName') }}
      </template>
      <template #content>
        <information-list-item-patient
          :reservation-info-list="reservationInfoList"
        />
      </template>
    </information-list-item>

    <information-list-item>
      <template #title>
        {{ $t('common.labelPhoneNumber') }}
      </template>
      <template #content>
        {{ reservationInfoList[0].tel }}
      </template>
    </information-list-item>

    <information-list-item>
      <template #title>
        {{ $t('common.labelEmail') }}
      </template>
      <template #content>
        {{ reservationInfoList[0].email }}
      </template>
    </information-list-item>

    <information-list-item>
      <template #title>
        {{ $t('common.labelAddress') }}
      </template>
      <template #content>
        {{ fullAddress }}
      </template>
    </information-list-item>

    <information-list-item>
      <template #title>
        {{ $t('common.labelRemarks') }}
      </template>
      <template #content>{{ reservationInfoList[0].memo }}</template>
    </information-list-item>
  </div>
</template>
<script>
import InformationListItem from '@/components/parts/molecules/InformationListItem'
import InformationListItemPatient from '@/components/parts/atoms/InformationListItemPatient.vue'
import {
  localizeValue,
  formatDate,
  formatStartTime
} from '@/utils/reservation_format'
import { mapGetters } from 'vuex'

export default {
  name: 'InformationListLoginReservation',

  components: {
    InformationListItemPatient,
    InformationListItem
  },

  props: {
    reservationInfoList: { type: Array }
  },

  computed: {
    ...mapGetters({
      getMasterDatum: 'master/getDataById'
    }),
    reservationPurpose() {
      const { purpose, purposeEnglish } = this.reservationInfoList[0]
      return localizeValue(purpose, purposeEnglish, this.$i18n.locale)
    },
    formattedDate() {
      const { date, time } = this.reservationInfoList[0]
      return (
        formatDate(date, this.$i18n.locale) +
        formatStartTime(time, this.$i18n.locale)
      )
    },
    ownerFullName() {
      const {
        ownerLastName: last,
        ownerFirstName: first
      } = this.reservationInfoList[0]
      return this.$t('common.ownerName', { first, last, honorific: '' })
    },
    ownerFullNameKana() {
      const {
        ownerLastNameKana,
        ownerFirstNameKana
      } = this.reservationInfoList[0]
      return `${ownerLastNameKana}\u{3000}${ownerFirstNameKana}`
    },
    fullAddress() {
      let address = ''
      if (this.reservationInfoList[0].postalCode) {
        address += `${this.reservationInfoList[0].postalCode}\n`
      }
      if (this.reservationInfoList[0].prefectureId) {
        address += this.getMasterDatum(
          'prefectures',
          this.reservationInfoList[0].prefectureId
        ).name
      }
      if (this.reservationInfoList[0].address) {
        address += this.reservationInfoList[0].address
      }
      if (this.reservationInfoList[0].building) {
        address += this.reservationInfoList[0].building
      }
      return address
    }
  }
}
</script>
