import moment from 'moment'

export const localizeValue = (value, valueEnglish, locale) => {
  return locale === 'en' ? valueEnglish || value : value
}

export const formatDate = (date, locale) => {
  if (locale === 'en') {
    moment.locale('en')
    return moment(date, 'YYYYMMDD').format('dddd, MMMM D, YYYY, ')
  } else {
    moment.locale('ja')
    return moment(date, 'YYYYMMDD').format('YYYY年MM月DD日（dd）')
  }
}

export const formatStartTime = (time, locale) => {
  if (locale === 'en') {
    moment.locale('en')
    return moment(time, 'HHmm').format('[from] h:mma')
  } else {
    moment.locale('ja')
    return moment(time, 'HHmm').format('HH時mm分[～]')
  }
}

export const formatName = (name, locale) => {
  return locale === 'ja' ? name : name.slice(0, -1)
}
