<template>
  <ul class="information-list-item">
    <li class="information-list-item-column title">
      <slot name="title" />
    </li>
    <li class="information-list-item-column content">
      <slot name="content" />
    </li>
  </ul>
</template>
<script>
export default {
  name: 'InformationListItem'
}
</script>
<style lang="scss" scoped>
.information-list-item {
  display: table;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  width: 1000px;
  word-wrap: break-word;
  table-layout: fixed;
  border-bottom: solid 2px #{$plus_ee_gray};
  > .information-list-item-column {
    display: table-cell;
    box-sizing: border-box;
    padding: 15px 10px 15px 0px;
    vertical-align: top;
    text-align: left;
    white-space: pre-line;
    @include font-size(18);
    &.title {
      width: 40%;
      font-weight: bold;
    }
    &.content {
      width: 60%;
    }
  }
}
$text-width: 410px;
@media (max-width: 960px) {
  .information-list-item {
    max-width: $text-width;
    width: calc-percent($text-width, 640px);
    display: block;
    border-bottom: none;
    > .information-list-item-column {
      display: block;
      list-style: none;
      padding: 0px;
      &.title {
        width: 100%;
        margin-bottom: 15px;
      }
      &.content {
        width: 100%;
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: solid 2px #{$plus_bc_gray};
      }
    }
  }
}
</style>
